.investEarn{
    border: 0.2px solid #FF823C;
    animation: myanimation 5s infinite ease;
}
@keyframes myanimation {
    0% {background-color: rgba(255,130,60,0.1);}
    25% {background-color: rgba(255,130,60,0.4);}
    50% {background-color: rgba(255,130,60,0.6);}
    75% {background-color: rgba(255,130,60,0.8);}
    100% {background-color: rgba(255,130,60,1);}
  }

  .textImage{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    /* border: 2px solid black; */
    overflow: hidden;
  }
  .textImage > img{
    width: 100%;
    height: 100%;
    object-fit:cover ;
  }

  .textImagelw{
    width: 210px !important;
    height: 70px;
    border-radius: 50px;
    /* border: 2px solid black; */
    overflow: hidden;
    display: block; 
  }
  .textImagelw > img{
    width: 100%;
    height: 100%;
    object-fit:cover ;
  }
  .textImagels{
    width: 210px !important;
    height: 50px;
    border-radius: 50px;
    /* border: 2px solid black; */
    overflow: hidden;
    display: none; 
  }
  .textImagels > img{
    width: 100%;
    height: 100%;
    object-fit:cover ;
  }
  .investNow{
    width: 230px;
    height: 230px;
    position: relative;
  }
  .investNow > img{
    height: 100%;
    width: 100%;
  }
  .investNowArrow{
    position: absolute;
    top: 0;
    left: 0;
    width: 230px;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .investNow2{
    width: 150px;
    height: 150px; 
    position: relative; 
    cursor: pointer;  
  }

  .investNow2pc{
    display: block;
  }
  .investNow2Mobile{
    display: none;
  }
  .intermediateArrow {
    animation: UpDown 1s linear infinite;
    
  }
  
  @keyframes UpDown {
    0% {
      margin-top: -10px;
    }
    50% {
      margin-top: 0px;
    }
   
    100% {
      margin-top: 10px;
    }
  }
  .arrowBox{
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; 
    transition: 0.5s;

  }
  .investNow2:hover .arrowBox{
    transform: rotate(-45deg);
    transition: 0.5s;
  }
  .proImgeIcon{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #FF823C;
    position: absolute;
    top: 5px;
    left: 15px;
    display: flex;
    align-items:center ;
    justify-content: center;
  }
  .proImgeDiv{
    cursor: pointer;
  }
  .proImgeIcon p{
    transform: rotate(-45deg);
    transition: 1s;
    cursor: pointer;
  }

  .proImgeDiv:hover .proImgeIcon p{
    transform: rotate(0deg);
    transition: 1s; 
  }
  .underText{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center; 
  }

  .invest2cartImg{
    border-radius: 0 20px 20px 0;
  }

  @media(max-width:992px){
    .invest2cartImg{
      border-radius: 20px 20px 0 0;
    }
  }

  @media(max-width:767px){
    .proImgeIcon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #FF823C;
        position: absolute;
        top: 5px;
        left: 15px;
        display: flex;
        align-items:center ;
        justify-content: center;
      }
      .textImage{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        /* border: 2px solid black; */
        overflow: hidden;
      }
      .textImagelw{
        width: 210px !important;
        height: 50px;
        border-radius: 50px;
        /* border: 2px solid black; */
        overflow: hidden;
        display: block; 
      }

      .investNow2{
        width: 120px;
        height: 120px; 
        position: relative; 
        cursor: pointer; 
      }

      .investNow2pc{
        display: none;
      }
      .investNow2Mobile{
        display: block;
      }
      .arrowBox{
        position: absolute;
        width: 120px;
        height: 120px;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff; 
        transition: 0.5s;
    
      }
      .investNow2:hover .arrowBox{
        transform: rotate(-45deg);
        transition: 0.5s;
      }
  }


  @media(max-width:557px){ 
      .textImagelw{
        width: 210px !important;
        height: 50px;
        border-radius: 50px;
        /* border: 2px solid black; */
        overflow: hidden;
        display: none; 
      }

      .textImagels{
        width: 210px !important;
        height: 50px;
        border-radius: 50px;
        /* border: 2px solid black; */
        overflow: hidden;
        display: block; 
      }
  }