.App {
  text-align: center;
 
}
.navbarMain{  
  position: fixed;
  overflow-x: hidden;
  top: 0;
  /* width: 100%; */
  z-index:9999 !important; 
  height: 70px;   
  transition: 300ms;
  display:flex;
  align-items: center; 
  /* -webkit-backdrop-filter: blur(1px);
backdrop-filter: blur(2px);   */
 
}
.navbarMain--hidden{
  transform: translateY(-07px);
  transition: 2000ms; 
}
.navLogo{
  opacity: 1;
  transition: 2s;
}
.navLogo.hide{
  opacity: 0;
  transition: 2s;
}

#hamburger2{
  position: relative;
  right:20px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: flex;
  gap: 3px;
  align-items: end;
  cursor: pointer;
}
.hamburger2 .lines{
  width: 6px; 
  background-color: #FF823C;
  display: flex;
  margin: 7px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  transition: 1s; 
}
.lines1{
  height: 15px;
}
.lines2{
  height: 23px;
}
.lines3{
  height: 30px;
}
.lines1.active{
  height: 30px;
}
.lines2.active{
  height: 23px;
}
.lines3.active{
  height: 15px;
}

.hamburger .line{
  width: 40px;
  height: 5px;
  background-color: #FF823C;
  display: block;
  margin: 7px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
  transition: 1s;
  
}

.hamburger:hover{
  cursor: pointer;
}

#hamburger{
  position: relative;
  right:20px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger.is-active{
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#hamburger:before{
  content: "";
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border: 5px solid transparent;
  top: calc(85% - 35px);
  left: calc(85% - 35px);
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#hamburger.is-active:before{
  border: 5px solid #FF823C; 

}

#hamburger.is-active .line{
  width: 25px;
  background-color: #FF823C; 
}

#hamburger.is-active .line:nth-child(2){
  opacity: 0;
  background-color: #FF823C;

}

#hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(13px);
  -ms-transform: translateY(13px);
  -o-transform: translateY(13px);
  transform: translateY(13px);
  background-color: #FF823C;

}

#hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-13px) rotate(90deg);
  -ms-transform: translateY(-13px) rotate(90deg);
  -o-transform: translateY(-13px) rotate(90deg);
  transform: translateY(-11px) rotate(90deg);
  background-color: #FF823C;

}
.menus{
  transition: clip-path 1s ease-in-out; 
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: #424242;
  z-index: 10!important;
  overflow-x: hidden;
  /* clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%); */
  clip-path: inset(0% 50% 100% 50% round 20px 20px 20px 20px);
  transition: 0.5s;

  /* animation: animationMenuHide 1s forwards;
  -webkit-animation: animationMenuHide 1s forwards; */
}
.menus.active {
  clip-path: inset(0% 0% 0% 0% round 20px 20px 20px 20px);
  transition: 0.5s;
  z-index: 3;


  /* animation: animationMenuShow 1s forwards;
  -webkit-animation: animationMenuShow 1s forwards; */
}

@keyframes animationMenuHide {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  50% {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  }
}

@-webkit-keyframes animationMenuHide /* Safari and Chrome */ {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  50% {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  }
}

@keyframes animationMenuShow {
  0% {
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  }
  50% {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@-webkit-keyframes animationMenuShow /* Safari and Chrome */ {
  0% {
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  }
  50% {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.tJustify{
  text-align: justify;
}
.footerAni{
  position: absolute;
  bottom: -50%;
  left: 0;
  transition: 2s;
  z-index: -1 !important; 
 
}

.footerAni.active{
  position: absolute;
  bottom: 10%;
  left: 0;
  transition: 2s; 
}
.mfs-8{
  font-size: 150px;
  line-height: 170px;
}
.mfs-7{
  font-size: 100px;
  line-height: 120px; 
}
.mfs-6{
  font-size: 45px;
  line-height: 55px; 
}
.mfs-5{
  font-size: 38px;
}
.mfs-4{
  font-size: 36px;
}
.mfs-3{
  font-size: 32px;
}
.mfs-2{
  font-size: 20px;
}
.mfs-1{
  font-size: 15px;
}

/* login page  */
.roundbox1{
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  gap: 10px;
  background:rgba(255,130,60,0.1); 
  border: 1px solid #ffd4bb;
  cursor: pointer;
}
.roundbox1 > span{
  /* background: #FF823C; */
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 20px;
  border-radius: 50%;
  font-weight: 500;
  transition: 0.5s ease-in-out;
}

.roundbox2{
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  gap: 10px;
  background:rgba(255,130,60,0.1); 
  border: 1px solid #ffd4bb;
  cursor: pointer;
  position: relative;
  transition: 1s;
}
.roundbox2 > span{
  /* background: #FF823C; */
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 20px;
  border-radius: 50%;
  font-weight: 500;
  transition: 0.5s ease-in-out;
}
.dumyToogle{
  background:#FF823C;
  color:#ffffff;
  position:absolute;
  top:4px; 
  transition:1s ease-in-out
}
 
.ring{
  clip-path: inset(0 100% 0 0);
  height: 65px; 
  transition: 3s; 
}
.ring img{
height:40px;

}
.ring.active{
  clip-path: inset(0 0 0 0); 
  transition: 3s;
}
.roundbox3{
  display: flex;
  align-items: center; 
  background-color: rgba(255,130,60,0.1);
  width: fit-content;
  padding: 0 10px;
  border: 1px solid #ffd4bb; 
  position: relative;
}
.roundbox3 > span{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #FF823C;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
 
 


.a-section {
 z-index: -1 !important;
 opacity: 0.3; 
}
.a-section-marquee-box {
  display: flex;
  align-items: center;
  overflow: hidden;  
}
.a-section-marquee-box h2 {
  white-space: nowrap; 
  text-transform: uppercase; 
  flex-shrink: 0;
  width: max-content;
  display: flex;
  align-items: center;
  transform: translateX(0);
  animation: a-text-scroll 35s linear infinite;
  line-height: 90px;
  color: #FF823C;
}
@keyframes a-text-scroll {
  0% { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(-100%, 0, 0); }
}

.sumBotTitle{
  position: relative;
  top: -80px;
}

.extraBigTitle{
  font-size: 220px;
  line-height: 220px;
}

.aboutSticky{
  height: 400vh;
}
.aboutSticky1{
  position: sticky;
  top: 0;
  height: 90vh;
  background-image: url('./Images/abg3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.aboutSticky2{
  position: sticky;
  top: 8%;
  height: 90vh;
  background-image: url('./Images/abg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.aboutSticky3{
  position: sticky;
  top: 16%;
  height: 90vh;
  background-image: url('./Images/abg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.aboutSticky4{
  position: sticky;
  top: 24%;
  height: 90vh;
  background: #FF823C; 
  /* background-image: url('./Images/abg3.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.rotation1{
  animation: rotation1 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.rotation2{
  animation: rotation2 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.rotation3{
  animation: rotation1 4.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.rotation4{
  animation: rotation1 7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes rotation1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
} 
 
.serviceTop{
  height: 100vh;
  overflow: hidden;
}


.pcVideo{
  display: block;
}

.mbVideo{
  display: none;
}
 
 


 
@media(max-width:1690px) {
  .extraBigTitle{
    font-size: 190px;
    line-height: 190px;
  }
  
  .mfs-8{
    font-size: 130px;
    line-height: 150px;
  }
  .mfs-7{
    font-size: 90px;
    line-height: 110px; 
  }
  .mfs-6{
    font-size: 42px;
    line-height: 52px;

  }
  .mfs-5{
    font-size: 36px;
  }
  .mfs-4{
    font-size: 34px;
  }
  .mfs-3{
    font-size: 25px;
  }
  .mfs-2{
    font-size: 20px;
  }
  .mfs-1{
    font-size: 15px;
  }
}

@media(max-width:1500px ) {
  .extraBigTitle{
    font-size: 150px;
    line-height: 160px;
  }
  .mfs-8{
    font-size: 100px;
    line-height: 120px; 
  }
  .mfs-7{
    font-size: 70px;
    line-height: 90px; 
  }
  .mfs-6{
    font-size: 35px;
    line-height: 45px; 
  }
  .mfs-5{
    font-size: 36px;
  }
  .mfs-4{
    font-size: 25px;
  }
  .mfs-3{
    font-size: 18px ;
  }
  .mfs-2{
    font-size: 16px;
  }
  .mfs-1{
    font-size: 14px;
  }
} 
 
@media(max-width:1280px) {
  .extraBigTitle{
    font-size: 150px;
    line-height: 150px;
  }
  .mfs-8{
    font-size: 80px;
    line-height: 100px; 
  }
  .mfs-7{
    font-size: 60px;
    line-height: 80px; 
  }
  .mfs-6{
    font-size: 35px;
    line-height: 45px; 
  }
  .mfs-5{
    font-size: 25px;
  }
  .mfs-4{
    font-size: 22px;
  }
  .mfs-3{
    font-size: 20px;
  }
  .mfs-2{
    font-size: 16px;
  }
  .mfs-1{
    font-size: 13px;
  }
}

@media(max-width:992px) {
  .serviceTop{
    margin-top: 80px;
    height: auto;
  }
  .extraBigTitle{
    font-size: 130px;
    line-height: 130px;
  }

  .sumBotTitle{
    position: relative;
    top: -50px;
  }
  .mfs-8{
    font-size: 70px;
    line-height: 90px; 
  }
  .mfs-7{
    font-size: 50px;
    line-height: 70px; 
  }
  .mfs-6{
    font-size: 30px;
    line-height: 40px; 
  }
  .mfs-5{
    font-size: 22px;
  }
  .mfs-4{
    font-size: 18px;
  }
  .mfs-3{
    font-size: 16px;
  }
  .mfs-2{
    font-size: 14px;
  }
  .mfs-1{
    font-size: 12px;
  }
}

 
@media(max-width:768px) {

  .extraBigTitle{
    font-size: 80px;
    line-height:100px; 
  }
  .sumBotTitle{
    position: relative;
    top: -10px;
  } 
 
  .mfs-8{
    font-size: 50px;
    line-height: 70px; 
  }
  .mfs-7{
    font-size: 35px;
    line-height: 55px; 
  }
  .mfs-6{
    font-size: 30px;
    line-height: 35px; 
  }
  .mfs-5{
    font-size: 25px;
  }
  .mfs-4{
    font-size: 16px;
  }
  .mfs-3{
    font-size: 14px;
  }
  .mfs-2{
    font-size: 12px;
  }
  .mfs-1{
    font-size: 10px;
  }


  .roundbox1{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    gap: 10px;
    background:rgba(255,130,60,0.1); 
    border: 1px solid #ffd4bb;
    cursor: pointer;
  }
  .roundbox1 > span{
    /* background: #FF823C; */
    color: white;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 20px;
    border-radius: 50%;
    font-weight: 500;
    transition: 0.5s ease-in-out;
  }
  
  .roundbox2{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    gap: 10px;
    background:rgba(255,130,60,0.1); 
    border: 1px solid #ffd4bb;
    cursor: pointer;
    position: relative;
    transition: 1s;
  }
  .roundbox2 > span{
    /* background: #FF823C; */
    color: white;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 20px;
    border-radius: 50%;
    font-weight: 500;
    transition: 0.5s ease-in-out;
  }
  .dumyToogle{
    background:#FF823C;
    color:#ffffff;
    position:absolute;
    top:4px; 
    transition:1s ease-in-out
  }
   
  .ring{
    clip-path: inset(0 100% 0 0);
    height: 65px; 
    transition: 3s; 
  }
  .ring img{
  height:40px;
  
  }
  .ring.active{
    clip-path: inset(0 0 0 0); 
    transition: 3s;
  }
  .roundbox3{
    display: flex;
    align-items: center; 
    background-color: rgba(255,130,60,0.1);
    width: fit-content;
    padding: 0 10px;
    border: 1px solid #ffd4bb; 
    position: relative;
  }
  .roundbox3 > span{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #FF823C;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
   
  
  
  
}

@media(max-width:600px){
  
  .pcVideo{
    display: none;
  }
  
  .mbVideo{
    display: block;
  }
}
/* @media(max-width:576px) {
  .mfs-8{
    font-size: 50px;
  }
  .mfs-7{
    font-size: 30px;
  }
  .mfs-6{
    font-size: 40px;
  }
  .mfs-5{
    font-size: 38px;
  }
  .mfs-4{
    font-size: 36px;
  }
  .mfs-3{
    font-size: 32px;
  }
  .mfs-2{
    font-size: 20px;
  }
  .mfs-1{
    font-size: 15px;
  }
} */

