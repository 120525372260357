.homeHero2{   
  position: relative;
  background-color: #424242; 
  background-size: 100% 100% ; 
}
.homeHero1{ 
  height: 200vh;  
  transition: 2s !important; 
}  
.homeHero1S{
  width: 100%;
  transition: 2s !important; 
}
.homeHero1R{
  position: relative;
  transition: 2s;
}
.homeHero1R1{
 width: 100%;
 height: 100vh;
 background-color:#ffffff ;
}

.homeHero1A{
  position:absolute;
  top:0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #424242;  */
  background-color: #000000; 

  /* transition: 0.5s ease; */
}
