.service-container { 
  height: 350vh; /* Ensures enough space for scrolling through each section */
  position: relative;
}

.service-container-sticky {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Changed from flex-end to flex-start for better alignment */
  height: 90vh;
  position: sticky;
  top: 50px;
  gap: 20px;
}

.sticky-section {
  position: sticky;
  top: 0;
  width: 33%; /* Initial width */
  padding: 20px;
  border: 1px solid #424242;
  border-radius: 20px;
  transition: width 1.5s cubic-bezier(0.42, 0, 0.58, 1), opacity 1.5s ease-in-out, background-color 1.5s ease-in-out;
  height: auto;
  box-sizing: border-box;
  background-color: rgba(255,255,255);
  text-align: start; 
}

.sticky-section.active {
  width: 50%; 
  background-color: rgba(255,130,60,0.1);
  border: 1px solid #FF823C;
  text-align: start;  
}

.service-header {
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  width: fit-content;
}

.serviceImageBox {
  height: 0;
  width: 0;
  transition: height 1.5s cubic-bezier(0.42, 0, 0.58, 1), width 1.5s cubic-bezier(0.42, 0, 0.58, 1), opacity 1.5s ease-in-out;
  opacity: 0.5;
  margin: auto;
}

.serviceImageBox.active {
  height: 45vh;
  width: 100%;
  opacity: 1;
  margin: auto;
}  

.service-description { 
  margin: 20px 0;
  transition: font-size 0.5s ease, margin 0.5s ease;
}

@media (max-width: 1400px) {
  .serviceImageBox.active {
    height: 30vh;
    width: 100%;
    opacity: 1;
    margin: auto;
  }  
}

@media (max-width: 992px) {
  .service-container {
    flex-direction: column;
    height: auto;
  }

  .sticky-section {
    width: 100% !important;
  }
}


  