.capMain { 
    display: flex;
    align-items: center;
    gap: 40px;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease;
}

.capMain.active {
    opacity: 1; /* Fully visible */
}

.capMain > p {
    border: 1px solid #ff823c;
    padding: 5px 20px;
    border-radius: 15px;
    font-weight: 600;
    color: #ff823c;
    transition: transform 0.8s ease, opacity 0.8s ease;
    opacity: 0; /* Initially hidden */
}

/* Individual capsule positions */
.cap1 {
    transition : 2s;
    transform: translate(530px, -256px);
}
.cap2 {
    transition : 2s;
    transform: translate(-530px, -156px);
}
.cap3 {
    transition : 2s;
    transform: translate(300px, 300px);
}
.cap4 {
    transition : 2s;
    transform: translate(-300px, 300px);
}
.cap5 {
    transition : 2s;
    transform: translate(500px, -400px);
}
.cap6 {
    transition : 2s;
    transform: translate(-500px, 400px);
}
.cap7 {
    transition : 2s;
    transform: translate(400px, -500px);
}
.cap8 {
    transition : 2s;
    transform: translate(-400px, 500px);
}
.cap9 {
    transition : 2s;
    transform: translate(600px, 200px);
}
.cap10 {
    transition : 2s;
    transform: translate(-600px, -200px);
}

/* When active */
.capMain.active > p {
    transform: translate(0, 0) !important; /* Reset to original position */
    opacity: 1; /* Fade in */
    transition: 2s;
}
