.loginBg{
  background: linear-gradient(#FFF3EC 50%,#fff 50%); 
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

}
.qnsSection {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  background: white;
}

.qnsSlide {
  transition: transform 0.3s ease-in-out;
  animation: slideIn 0.5s ease;
  height: 100%;
  width: 100%;
  position: relative;
  background-image: url("../../Images/qnsBg4.png");
  background-size: auto 60%;
  background-repeat: no-repeat;
  background-position: 0% 40%;
  transition: background-position 0.5s ease-in-out;
}

.ansDivWind {
  transition: 1s;
  display: block;
  position: absolute;
  box-shadow: rgba(66, 66, 66, 0.4) 8px 8px, rgba(66, 66, 66, 0.3) 9px 9px,
    rgba(66, 66, 66, 0.2) 10px 10px;
}

/* .ansDivMobile {
  transition: 1s;
  position: absolute;
  display: none;
  box-shadow: rgba(66, 66, 66, 0.4) 8px 8px, rgba(66, 66, 66, 0.3) 9px 9px,
    rgba(66, 66, 66, 0.2) 10px 10px;
} */

.qnsN {
  position: absolute;
  width: 80px;
  height: 80px;
  left: -100px;
  border-radius: 50%;
  border: 3px solid #ff823c;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff823c;
}
.qnslN{
  position: absolute;
  width: 80px;
  height: 80px;
  left: 5%;
  top: 40%;
  border-radius: 50%;
  border: 3px solid #ff823c;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff823c;
  cursor: pointer;
}

.qnsrN{
  position: absolute;
  width: 80px;
  height: 80px;
  left: 90%;
  top: 40%;
  border-radius: 50%;
  border: 3px solid #ff823c;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff823c;
}
.qnsrB{
  position: absolute; 
  left: 90%;
  top: 40%; 
  background-color: #ff823c; 
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@media (max-width: 770px) {
  .qnsSlide {
    height: 100%;
    width: 100%;
    position: relative;
    background-image: url("../../Images/qnsBg3.png");
    background-size: auto 40%;
    background-repeat: no-repeat;
    background-position: 0% 50%;
    transition: background-position 0.8s ease-in-out;
    display: flex;
    align-items: end;
    justify-content: center;
    background-position: 0% 20%;
  }
  .ansDivWind {
    display: block;
    position: absolute;
    transition: 1s;

    box-shadow: rgba(66, 66, 66, 0.4) 8px 8px, rgba(66, 66, 66, 0.3) 9px 9px,
      rgba(66, 66, 66, 0.2) 10px 10px;
  }

  /* .ansDivMobile {
    bottom: 100px;
    position: relative;
    transition: 1s; 
    display: block;
    box-shadow: rgba(66, 66, 66, 0.4) 8px 8px, rgba(66, 66, 66, 0.3) 9px 9px,
      rgba(66, 66, 66, 0.2) 10px 10px;
  } */

  .qnsN {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 0px;
    top: -90px;
    border-radius: 50%;
    border: 1px solid #ff823c;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qnslN{
    position: absolute;
    width: 80px;
    height: 80px;
    left: 5%;
    top: 30%;
    border-radius: 50%;
    border: 3px solid #ff823c;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff823c;
    cursor: pointer;
  }
  
  .qnsrN{
    position: absolute;
    width: 80px;
    height: 80px;
    left: 70%;
    top: 30%;
    border-radius: 50%;
    border: 3px solid #ff823c;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff823c;
  }

  .qnsrB{
    position: absolute; 
    left: 70%;
    top: 30%; 
    background-color: #ff823c; 
  }
}
