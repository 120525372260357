.homeHero{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .capMain{
    position: relative;
    height: 100%;
  }
.portfoioList {
  /* position: absolute;
  top: 0; */
  border: 1px solid #ff823c;
  padding: 5px 20px;
  border-radius: 15px;
  transition: 2s ease;
  font-weight: 600;
  color: #ff823c;
}
/* 
.p1 {
  top: -100px;
  left: -100px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p1.active {
  top: 75%;
  left: 10%;
  transition: 2s ease;
  transform: rotate(-10deg);
  animation: swing ease-in-out 2s alternate;
}
.p2 {
  top: -100px;
  left: -100px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p2.active {
  top: 55%;
  left: 15%;
  transition: 1s ease;
  transform: rotate(15deg);
  animation: swing ease-in-out 2s alternate; 

}
.p3 {
  top: -100px;
  left: -100px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p3.active {
  top: 45%;
  left:30%;
  transition: 2s ease;
  transform: rotate(-20deg);
  animation: swing ease-in-out 2s alternate;

}
.p4 {
  top: -100px;
  left: -100px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p4.active {
  top: 80%;
  left: 65%;
  transition: 3s ease;
  transform: rotate(10deg);
  animation: swing ease-in-out 2s alternate;

}
.p5 {
  top: -100px;
  left: -100px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p5.active {
  top: 55%;
  left: 35%;
  transition: 1s ease;
  transform: rotate(-5deg);
  animation: swing ease-in-out 2s alternate;

}
.p6 {
  top: -100px;
  right: -100px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p6.active {
  top: 90%;
  right: 35%;
  transition: 2s ease;
  transform: rotate(0deg);
  animation: swing ease-in-out 2s alternate;

}

.p7 {
  top: -100px;
  right: -100px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p7.active {
  top: 73%;
  right: 38%;
  transition: 3s ease;
  transform: rotate(10deg);
  animation: swing ease-in-out 2s alternate;

}
.p8 {
  top: -100px;
  right: -90px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p8.active {
  top: 55%;
  right: 33%;
  transition: 1s ease;
  transform: rotate(-30deg);
  animation: swing ease-in-out 2s alternate;

}
.p9 {
  top: -100px;
  right: -80px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p9.active {
  top: 70%;
  right: 55%;
  transition: 2s ease;
  transform: rotate(20deg);
  animation: swing ease-in-out 2s alternate;


}
.p10 {
  top: -100px;
  right: -100px;
  transition: 0.5s ease;
  transform: rotate(0deg);
}
.p10.active {
  top: 85%;
  right: 59%;
  transition: 3s ease;
  transform: rotate(-15deg);
  animation: swing ease-in-out 2s alternate;
 
} */

@keyframes swing {
  0% {
    transform: rotate(20deg);
  }
  15% {
    transform: rotate(-20deg);
  }
  30% {
    transform: rotate(15deg);
  }
  45% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(15deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  90% {
    transform: rotate(13deg);
  }
  100% {
    transform: rotate(26deg);
  }
}

.servicePc {
  display: block;
}
.serviceMobile {
  display: none;
}
.serviceSmall1 {
  border: 1px solid #424242;
  background: white;
  transition: 1s;
}
.serviceSmall1.active {
  border: 1px solid #ff823c;
  background: rgba(255, 130, 60, 0.1);
  transition: 2s;
}
.servisJson1 {
  height: 0;
  opacity: 0.5;
  transition: 1s;
}
.servisJson1.active {
  height: 300px;
  opacity: 1;
  transition: 2s;
}

.serviceSmall2 {
  border: 1px solid #424242;
  background: white;
  transition: 1s;
}
.serviceSmall2.active {
  border: 1px solid #ff823c;
  background: rgba(255, 130, 60, 0.1);
  transition: 2s;
}
.servisJson2 {
  height: 0;
  opacity: 0.5;
  transition: 1s;
}
.servisJson2.active {
  height: 300px;
  opacity: 1;
  transition: 2s;
}

.serviceSmall3 {
  border: 1px solid #424242;
  background: white;
  transition: 1s;
}
.serviceSmall3.active {
  border: 1px solid #ff823c;
  background: rgba(255, 130, 60, 0.1);
  transition: 2s;
}
.servisJson3 {
  height: 0;
  opacity: 0.5;
  transition: 1s;
}
.servisJson3.active {
  height: 300px;
  opacity: 1;
  transition: 2s;
}
.arrows{
    position: absolute;
    bottom: 3%;
    left: 0%;
    width: 100%; 
}
.arrows p {
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
  color: #424242;
 
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.arrows p.d1 {
	animation-delay:-1s; 
	-webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows p.d2 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
    margin-top: -15px;
}

.arrows p.d3{	
	animation-delay:0s;
    margin-top: -15px;

	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}
.homeAboutPC{
  display: block;
}
.homeAboutMB{
  display: none;
}

@media (max-width: 1180px) {
  .servicePc {
    display: none;
  }
  .serviceMobile {
    display: block;
  }
}

@media (max-width: 992px) {
  
  .homeHero{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .p1.active {
    top: 40%;
    left: 10%;
    transition: 2s ease;
    transform: rotate(-20deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p2.active {
    top: 48%;
    left: 35%;
    transition: 1s ease;
    transform: rotate(20deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p3.active {
    top: 40%;
    left: 55%;
    transition: 2s ease;
    transform: rotate(-20deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p4.active {
    top: 60%;
    left: 15%;
    transition: 3s ease;
    transform: rotate(10deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p5.active {
    top: 65%;
    left: 50%;
    transition: 1s ease;
    transform: rotate(0deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p6.active {
    top: 80%;
    right: 33%;
    transition: 2s ease;
    transform: rotate(30deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p7.active {
    top: 65%;
    right: 55%;
    transition: 3s ease;
    transform: rotate(-30deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p8.active {
    top: 65%;
    right: 15%;
    transition: 1s ease;
    transform: rotate(-30deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p9.active {
    top: 85%;
    right: 50%;
    transition: 2s ease;
    transform: rotate(20deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p10.active {
    top: 85%;
    right: 10%;
    transition: 3s ease;
    transform: rotate(15deg);
    animation: swing ease-in-out 2s alternate;
  }
}

@media (max-width: 768px) {
  .homeAboutPC{
    display: none;
  }
  .homeAboutMB{
    display: block;
  }
}


@media (max-width: 600px) {
 
  .p1.active {
    top: 30%;
    left:8%;
    transition: 2s ease;
    transform: rotate(-20deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p2.active {
    top: 35%;
    left: 50%;
    transition: 1s ease;
    transform: rotate(20deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p3.active {
    top: 40%;
    left: 15%;
    transition: 2s ease;
    transform: rotate(-20deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p4.active {
    top: 45%;
    left: 45%;
    transition: 3s ease;
    transform: rotate(10deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p5.active {
    top: 53%;
    left: 20%;
    transition: 1s ease;
    transform: rotate(25deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p6.active {
    top: 70%;
    right: 10%;
    transition: 2s ease;
    transform: rotate(30deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p7.active {
    top: 65%;
    right: 55%;
    transition: 3s ease;
    transform: rotate(-30deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p8.active {
    top: 55%;
    right: 15%;
    transition: 1s ease;
    transform: rotate(-30deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p9.active {
    top: 75%;
    right: 50%;
    transition: 2s ease;
    transform: rotate(-20deg);
    animation: swing ease-in-out 2s alternate;
  }
  .p10.active {
    top: 85%;
    right: 25%;
    transition: 3s ease;
    transform: rotate(2deg);
    animation: swing ease-in-out 2s alternate;
  }
}
