/* bootstrap icon  */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
/* google font */ 
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap'); */
 

html {
  overscroll-behavior: none; 
  scroll-behavior: smooth !important;  
  -ms-overflow-style: none; /* Hides scrollbar for Internet Explorer and Edge */
  overflow: -moz-scrollbars-none; /* Hides scrollbar for older Firefox */
  scrollbar-width: none; 
}

@font-face {
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-Thin.otf') format('opentype');  
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-ExtraLight.otf') format('opentype'); 
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-Light.otf') format('opentype'); 
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-Regular.otf') format('opentype'); 
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-Medium.otf') format('opentype'); 
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-SemiBold.otf') format('opentype'); 
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-Bold.otf') format('opentype'); 
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-ExtraBold.otf') format('opentype'); 
  font-style: normal;
  font-weight: 800;
} 
@font-face {
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-Heavy.otf') format('opentype'); 
  font-style: normal;
  font-weight: 900;
}
.fwt {
  font-family: 'Blauer Nue';
  font-weight: 100;
}
.fwel {
  font-family: 'Blauer Nue';
  font-weight: 200;
}
.fwl{
  font-family: 'Blauer Nue';
  font-weight: 300;
}
.fwr {
  font-family: 'Blauer Nue';
  font-weight: 400;
}
.fwm {
  font-family: 'Blauer Nue';
  font-weight: 500;
}
.fwsb {
  font-family: 'Blauer Nue';
  font-weight: 600;
}
.fwb {
  font-family: 'Blauer Nue';
  font-weight: 700;
}
.fweb {
  font-family: 'Blauer Nue';
  font-weight: 800;
}
.fwh {
  font-family: 'Blauer Nue';
  font-weight: 900;
}



 
*{
  margin: 0;
  padding: 0; 
  scroll-behavior: smooth;
}
p{
  margin: 0;
  padding: 0;  
}
body {
  margin: 0;
  font-family: 'Blauer Nue';
  src: url('./fontFamily/BlauerNue-ExtraLight.otf') format('opentype');  
  font-weight:100 ; 
  font-style: normal;
  font-synthesis: weight;
  color: #424242;
}

.org{
  color:#FF823C  !important;
}
.blackGray{
  color: #424242 !important;
}

.bg-org{
  background-color:#FF823C  !important;
}
.bg-blackGray{
  background-color: #424242 !important;
} 



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sumBtn{
  background-color:transparent;
  border-radius: 20px; 
  border: 0.5px solid #FF823C;  
  color: #FF823C;
  cursor: pointer;
  padding: 8px 24px;
  font-weight: 800;
  
}

.sumBtn:hover{
  background-image: url(./Images/buttonBg.jpg);
  /* background-repeat: no-repeat; */
  background-size: auto 100%;
  animation:slidebg 7s linear infinite;
  color: transparent; 
}

.swal2-actions > button{
  background-color:transparent;
  border-radius: 20px; 
  border: 0.5px solid #FF823C;  
  color: #FF823C;
  cursor: pointer;
  padding: 8px 24px;
  font-weight: 800;
}

.swal2-actions > button:hover{
background-color: #FF823C; 
  color: white; 
}

@keyframes infiniteScroll {
  from {transform: translateX(0)}
  to {transform: translateX(-50%)}
}
 
@keyframes slidebg {
  to {
    background-position:20vw;
  }
}

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap; 
  padding: 1rem 0;
  color: #fff;
}

.marquee__item {
  display: inline-block;
  will-change: transform;
  animation: marquee 25s linear infinite; 
}

.marquee:hover .marquee__item {
  animation-play-state: paused;
}

.marquee__seperator {
  margin: 0 2rem;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.ourPartnerPc{
  display: block;
}
.ourPartnerMb{
  display: none;
}

@media(max-width:992px){ 
  .ourPartnerPc{
    display: none;
  }
  .ourPartnerMb{
    display: block;
  }
  .sumBtn:hover{
    background-image: none; /* Remove the background image on hover */
    color: #FF823C; /* Keep the original color */
    animation: none;
  }
}

.homeDownBtn{
  position: absolute;
  bottom: 3%;
  right: 2%;
  background-color: #FF823C;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: none;
  z-index: 10;
}


.homeBtn > p{
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
  font-weight: 900;
  font-size: 20px;

}
 
@-webkit-keyframes mover {
  0% { transform: translateY(3px); }
  100% { transform: translateY(-5px); }
}
@keyframes mover {
  0% { transform: translateY(3px); }
  100% { transform: translateY(-5px); }
}

.accordion-button{
  background-color: rgba(66,66,66,0.3) !important; 
  color: #424242;
}


::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

/* For Firefox */
* {
  scrollbar-width: none; /* Hides scrollbar */
  -moz-scrollbar-width: none;
}

 