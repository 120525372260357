.sticky-parent {
    height: 800vh;  
    position: relative;  
}

.sticky {
    position: sticky;
    top: 0; 
    max-height: 100vh;  
    overflow-x: hidden;  
    overflow-y: hidden;  
}

.horizontal {
    display: flex; 
}

.dim {
    min-width: 100vw;  
    height: 100vh;  
    align-items: center;
    justify-content: center; 
}
.dim > div{
    width: 100vw !important;
    height: 100vh;
    margin:  auto;
    border-radius: 0  30px 30px 0;
}
 

.firstDiv{
    width: 100vw !important;
    height: 100%;
    margin:  auto;
    border-radius: 0  30px 30px 0;
    background: #FF823C;  
    background-image: url('../../Images/homeAbouBg.png');
    background-position: center;
    background-size: 90%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden;
}
.firstDivDown { 
    position: absolute;
    left: 0%;
    top: 0%;  
    transition: 5s; 
    z-index: 0;
    opacity: 0.5;
}
.firstDivDown.active{
    position: absolute;
    left: 0%;
    top: 90%;  
    transition: 5s ; 
}
.firstDivUp {
    position: absolute;
    right: 0%;
    bottom: 0%;  
    transition: 5s ; 
    z-index: 0;
    opacity: 0.5;
}
.firstDivUp.active {
    position: absolute;
    right: 0%;
    bottom: 90%;
    transition: 5s; 
}
.firstDivText{
    position: absolute;
    right: -30%;
    bottom: 0%; 
    z-index: 0;
    opacity: 0.3;
    color: #fff; 
    transition: 3s ease-in-out;   
    text-shadow:
    -1px -1px 0 #fff,  
     1px -1px 0 #fff,
     -1px 1px 0 #fff,
      1px 1px 0 #fff
}
.firstDivText.active{
    position: absolute;
    text-wrap: nowrap;
    right: 20%;
    bottom: 0%;  
    color: #fff; 
    transition: 3s ease-in-out;  
    text-shadow:
    -1px -1px 0 #fff,  
     1px -1px 0 #fff,
     -1px 1px 0 #fff,
      1px 1px 0 #fff
}
.secondDiv{
    width: 100vw !important;
    height: 100%;
    margin:  auto;
    border-radius: 0  30px 30px 0;
    background: #402E32;   
    background-image: url('../../Images/homeAbouBg.png');
    background-position: center;
    background-size: 90%; 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: 5s;
    overflow: hidden;

}
.secondDivRight {
    position: absolute;
    left: 0%;
    top: 0%;  
    transition: 5s;
    z-index: 0;
    opacity: 0.5;
}
.secondDivRight.active {
    position: absolute;
    left: 90%;
    top: 0%;  
    transition: 5s;
}

.secondDivLeft {
    position: absolute;
    right: 0%;
    bottom: 0%;   
    transition: 5s;
    z-index: 0;
    opacity: 0.5;
}
.secondDivLeft.active {
    position: absolute;
    right: 90%;
    bottom: 0%; 
    transition: 5s;  
}
.secondDivText{
    position: absolute;
    z-index: 0;
    opacity: 0.3;
    right: -20%;
    bottom: 0%;  
    color: #fff;
    transition: 5s; 
    text-shadow:
    -1px -1px 0 #fff,  
     1px -1px 0 #fff,
     -1px 1px 0 #fff,
      1px 1px 0 #fff 
}
.secondDivText.active{
    position: absolute;
    text-wrap: nowrap; 
    right: 10%;
    bottom: 0%;  
    color: #fff;
    transition: 5s; 
    text-shadow:
    -1px -1px 0 #fff,  
     1px -1px 0 #fff,
     -1px 1px 0 #fff,
      1px 1px 0 #fff 
}
.thirdDiv{
    width: 100vw !important;
    height: 100%; 
    border-radius: 0  30px 30px 0;
    background: #FFF5F0;   
    background-image: url('../../Images/homeAbouBg.png');
    background-position: center;
    background-size: 90%; 
    position: relative !important;
  display: flex;
  align-items: center;
  justify-content: center;
    color: black; 
    overflow: hidden;
}
.thirdDivRight {
    position: absolute;
    left: 0%;
    top: 0%;   
    transition: 5s;
    z-index: 0;
    opacity: 0.5;
}
.thirdDivRight.active {
    position: absolute;
    left: 90%;
    top: 0%;
    transition: 5s;   
}
.thirdDivLeft {
    position: absolute;
    right: 0%;
    bottom: 0%;   
    transition: 5s;
    z-index: 0;
    opacity: 0.5;
}
.thirdDivLeft.active {
    position: absolute;
    right: 90%;
    bottom: 0%;   
    transition: 5s;
}
.thirdDivText{
    position: absolute;
    z-index: 0;
    opacity: 0.3;
    left: 80%;
    bottom: 0%;  
    color: #000;
    transition: 5s; 
    text-shadow:
    -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000 
}
.thirdDivText.active{
    position: absolute;
    text-wrap: nowrap; 
    left: 10%;
    bottom: 0%;  
    color: #000;
    transition: 5s; 
    text-shadow:
    -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000 
}
.forthDiv{
    width: 100vw !important;
    height: 100%;
    margin:  auto;
    border-radius: 0  30px 30px 0;
    background: #ffffff;  
    background-image: url('../../Images/homeAbouBg.png');
    background-position: center;
    background-size: 90%;  
    position: relative !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.htp1{
    font-size: 60px;
}
.htp2{
    font-size: 40px;
}
.htp3{
    font-size: 25px;
}
.ctaText{
    text-transform: uppercase;
    background-image: linear-gradient(
      260deg,
      #FF823C 0%,
      #d65002 40%,
      #b94400 70%,
      #424242 100%
      );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 3s linear infinite;
    display: inline-block;
      
}

@keyframes textclip {
    to {
      background-position: -200% center;
    }
  }
 

@media (max-width : 1680px) {
    .htp1{
        font-size: 55px;
    }
    .htp2{
        font-size: 35px;
    }
    .htp3{
        font-size: 20px;
    }
}


@media (max-width : 1680px) {
    .htp1{
        font-size: 55px;
    }
    .htp2{
        font-size: 35px;
    }
    .htp3{
        font-size: 20px;
    }
}

@media (max-width : 1360px) {
    .htp1{
        font-size: 50px;
    }
    .htp2{
        font-size: 30px;
    }
    .htp3{
        font-size: 18px;
    }
}

@media (max-width : 1152px) {
    .htp1{
        font-size: 45px;
    }
    .htp2{
        font-size: 25px;
    }
    .htp3{
        font-size: 16px;
    }
}

@media (max-width : 992px) {
    .htp1{
        font-size: 25px;
    }
    .htp2{
        font-size: 18px;
    }
    .htp3{
        font-size: 11px;
    }
    .sticky-parent {
        height: 800vh;  
        position: relative;  
    }
     
}

@media (max-width : 778px) {
    .htp1{
        font-size: 25px;
    }
    .htp2{
        font-size: 18px;
    }
    .htp3{
        font-size: 11px;
    }
    .sticky-parent {
        height: 450vh;  
        position: relative;  
    }
     
}


 

